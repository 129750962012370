<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <v-form @submit.prevent>
          <div>Email</div>
          <v-text-field v-model="loginDTO.email"></v-text-field>
          <br><br>
          <div>Wachtwoord</div>
          <v-text-field v-model="loginDTO.code"></v-text-field>
          <v-btn @click="LoginWith2FA">2FA login</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "twofactor",
  data(){
    return {
      loginDTO:{
        code: '',
        email: '',
      }
    }
  },
  methods:{
    LoginWith2FA(){
      axios.post("http://localhost:5180/api/Account/verify?code=" + this.loginDTO.code + "&email=" + this.loginDTO.email).then(response =>
      {
        console.log(response);
        if (response.data.status === 1){
          alert("2fa verkeerd ingevuld log opnieuw in");
          this.$router.push({path: '/login'});
        }

        if (response.data.status === 0)
        {
          alert("je bent ingelogd met 2fa");
          console.log(response);
          var loginData = {
            email: this.loginDTO.email,
            jwtToken: response.data.data.token,
            username: this.loginDTO.email,
            playerId: response.data.data.playerId
          }

          this.$store.commit("login", loginData)
          console.log(this.$store.state)
          this.$router.push({path: '/games'});
        }
      })
    }
  },

  created() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
  }
}
</script>
