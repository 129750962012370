<template>
  <v-app>
    <v-app-bar color="primary" app>
      <div class="body-1 font-weight-bold text--white">Showcase</div>
      <v-btn text to="/">Home</v-btn>
      <v-btn text to="/about">Contact</v-btn>
      <v-btn text to="/games">Games</v-btn>
      <v-btn text to="/statistics/01af4b6b-e565-488c-8b11-36ba3c8ec2e4">Statistieken</v-btn>
      <v-btn text to="/players">Speler beheer</v-btn>
      <v-spacer></v-spacer>
      <v-btn text v-if="$store.state.email.length === 0" to="/login">Log in</v-btn>
      <v-btn text v-if="$store.state.email.length === 0" to="/register">Registreren</v-btn>
      <v-btn text v-if="$store.state.email.length !== 0" @click="logout">Log out</v-btn>
    </v-app-bar>

    <v-main>
    <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  methods: {
    logout() {
      this.$store.commit("logout")
    }
  },

  created() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
    this.$store.commit("initializeStore")
  }
}
</script>
