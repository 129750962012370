<template>
<v-container>
  <v-col>
  <div class="text-h2 font-weight-bold">Wie is aan de beurt</div>
    <div class="text-h3">{{WhoTurnIsIt()}}</div>
  <div class="text-h2 font-weight-bold">Spel status</div>
    <div class="text-h3">{{SpelStatus()}}</div>
  </v-col>
  <v-row justify="center">
  <v-col cols="12" sm="12" xs="12" md="12" lg="6" xl="6">
    <div class="text-h4 font-weight-bold text-center my-4">Jouw Bord</div>
      <v-row class="justify-center" v-for="(row, rowIndex) in 10" :key="rowIndex">
        <div
            v-for="(col, colIndex) in 10" :key="colIndex"
            class="ma-1"
        >
          <v-img v-if="GetCellStatus(row, col) === 0" height="20px" width="20px" src="../assets/water.png"></v-img>
          <v-img v-else-if="GetCellStatus(row, col) === 1" height="20px" width="20px" src="../assets/green.png"></v-img>
          <v-img v-else-if="GetCellStatus(row, col) === 2" height="20px" width="20px" src="../assets/hit.png"></v-img>
          <v-img v-else-if="GetCellStatus(row, col) === 3" height="20px" width="20px" src="../assets/miss.png"></v-img>
        </div>
      </v-row>
  </v-col>
    <v-col cols="12" sm="12" xs="12" md="12" lg="6" xl="6">
      <div class="text-h4 font-weight-bold text-center my-4">{{GetSecondPlayerBoardName()}}</div>
      <v-row class="justify-center" v-for="(row, rowIndex) in 10" :key="rowIndex">
        <div
            v-for="(col, colIndex) in 10" :key="colIndex"
            class="ma-1">
          <v-img @click="MakeShot(row, col)" v-if="GetShotsStatusOpponent(row, col) === 0" height="20px" width="20px" src="../assets/water.png"></v-img>
          <v-img v-else-if="GetShotsStatusOpponent(row, col) === 2" height="20px" width="20px" src="../assets/hit.png"></v-img>
          <v-img v-else-if="GetShotsStatusOpponent(row, col) === 3" height="20px" width="20px" src="../assets/miss.png"></v-img>
        </div>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Battleship',
  data() {
    return {
      game:{},
      cells: {},
      shots: {}
    }
  },
  methods: {
    async getGame() {
      try {
        let response = await axios.get("http://localhost:5180/api/Game/GetGame?gameId=" + this.$route.params.id);
        this.game = response.data.data;
        console.log(this.game);
      } catch (error) {
        console.error("Error fetching game data:", error);
      }
    },
    async getCells() {
      if (this.game.player2Id === this.$store.state.playerId) {
        await axios.get("http://localhost:5180/api/Game/GetCells?boardId=" + this.game.boardPlayer2Id)
            .then(
                response => {
                  this.cells = response.data.data;
                  this.$forceUpdate();
                });
      } else {
        await axios.get("http://localhost:5180/api/Game/GetCells?boardId=" + this.game.boardPlayer1Id)
            .then(
                response => {
                  this.cells = response.data.data;
                  this.$forceUpdate();
                });
      }
    },
    GetCellStatus(x, y) {
      const cell = this.cells.find(cell => cell.x === x && cell.y === y);
      return cell ? cell.status : 0;
    },
    GetShotsStatusOpponent(x, y) {
      const shot = this.shots.find(shot => shot.cell.x === x && shot.cell.y === y);
      return shot ? shot.status : 0;
    },
    async MakeShot(x, y) {
      await axios.post("http://localhost:5180/api/Game/MakeShot?playerId=" + this.$store.state.playerId + "&gameId=" + this.$route.params.id + "&x=" + x + "&y=" + y);
      this.$forceUpdate();
    },
    GetSecondPlayerBoardName() {
      if (this.game.player2Id === this.$store.state.playerId) {
        return this.game.player1.name;
      }

      return this.game.player2.name
    },
    async GetShots() {
      if (this.game.player2Id === this.$store.state.playerId) {
        await axios.get("http://localhost:5180/api/Game/GetShots?boardId=" + this.game.boardPlayer1Id)
            .then(
                response => {
                  this.shots = response.data.data;
                  this.$forceUpdate();
                });
      } else {
        await axios.get("http://localhost:5180/api/Game/GetShots?boardId=" + this.game.boardPlayer2Id)
            .then(
                response => {
                  this.shots = response.data.data;
                  this.$forceUpdate();
                });
      }
    },
    WhoTurnIsIt() {
      if (this.game.currentTurn === this.$store.state.playerId) {
        return "Jouw beurt";
      }

      return "Tegenstanders beurt"
    },
    SpelStatus() {
      if (this.game.status === 0) {
      return "Tegenstander zoeken";
      }
      else if (this.game.status === 2) {
        return "Is bezig";
      }
      else if (this.game.status === 3) {
        return "Afgelopen";
      }

  },
    async FetchData(){
      await this.getGame();
      await this.getCells();
      await this.GetShots();
    }
  },

  async created() {
    await this.FetchData();

    setInterval(await this.FetchData, 5000);
  }
}
</script>
