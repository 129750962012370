import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BattleShip from "../views/BattleShip.vue";
import LoginPage from "@/views/LoginPage.vue";
import Games from "@/views/Games.vue";
import Statistics from "@/views/Statistics.vue";
import Players from "@/views/Players.vue";
import Admin from "@/views/Admin.vue";
import Register from "@/views/Register.vue";
import Twofactor from "@/views/Twofactor.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/battleship/:id',
    name: 'battleship',
    component: BattleShip
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/games',
    name: 'games',
    component: Games
  },
  {
    path: '/statistics/:id',
    name: 'statistics',
    component: Statistics
  },
  {
    path: '/players',
    name: 'players',
    component: Players
  },
  {
    path: '/players/:id',
    name: 'player',
    component: Admin
  },
  {
    path: "/register",
    name: 'register',
    component: Register
  },
  {
    path: "/2fa",
    name: 'twofactor',
    component: Twofactor
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
