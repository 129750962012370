<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <v-img src="../assets/djurre.png"></v-img>
        <div class="text-h3 font-weight-bold my-4">Djurre de Jong</div>
        <div>Ik ben een gepassioneerde software developer die al 1 jaar met C# .NET werkt en momenteel werkzaam ben als junior softwareontwikkelaar bij CareConnection in Leusden.</div>
        <div class="my-2">Mijn passie voor software development komt voort uit de creativiteit en uitdaging die het met zich meebrengt. Ik vind het erg leuk om complexe problemen op te lossen door middel van code en om te zien hoe mijn creaties tot leven komen.</div>
        <div class="text-h4 font-weight-bold">Opleidingen</div>
        <v-list two-line three-line>
          <v-list-item>
            <v-list-item-content>
            <v-list-item-title>HAVO</v-list-item-title>
            <v-list-item-subtitle>Op alberdink thijm college</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
            <v-list-item-title>HBO</v-list-item-title>
            <v-list-item-subtitle>Op windesheim Zwolle</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="text-h4 font-weight-bold">Personalia</div>
        <v-list>
          <v-list-item>
            <v-list-item-subtitle>djurrebax@gmail.com</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>0634556664</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle>Gerard Doustraat 4 8021 EP</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },

  created() {
    for (var i = 1; i < 99999; i++)
      window.clearInterval(i);
  }
}
</script>
