<template>
<v-container>
<div class="text-h2 font-weight-bold">Naam</div>
  <v-row justify="center">
    <v-col cols="6" justify="center">
      <div class="text-h2 text-center">Aantal Gewonnen</div>
      <div class="text-h3 text-center">{{player.wins}}</div>
    </v-col>
    <v-col cols="6">
      <div class="text-h2 text-center">Aantal Verloren</div>
      <div class="text-h3 text-center">{{player.losses}}</div>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: "Statistics",
  data(){
    return {
      player: {}
    }
  },
  methods: {
    GetStatistics() {
      axios.get("http://localhost:5180/api/Statistics/GetStatics?jwtToken=01af4b6b-e565-488c-8b11-36ba3c8ec2e4")
          .then(response =>
          {
              this.player = response.data.data;
              console.log(response)
          });
    }
  },
  created() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
    this.GetStatistics()
  }
}
</script>
