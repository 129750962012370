<template>
<v-container>
  <v-row justify="center">
    <v-col justify="center" class="mt-6" >
      <v-row justify="center" v-for="player in players" :key="player.id">
        <div class="text-center align-center">{{player.name}}</div>
        <v-btn class="bottom justify-center" :to="'/players/' + player.id">Ga naar speler</v-btn>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Players",
  data(){
    return {
      players: {}
    }
  },
  methods: {
    Players() {
      axios.get("http://localhost:5180/api/Admin/GetAllPlayers")
          .then(response =>
          {
            this.players = response.data.data;
            console.log(response)
          });
    }
  },
  created() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }

    this.Players()
  }
}
</script>
