<template>
<v-container>
    <v-col>
      <v-row justify="center">
        <v-form @click.prevent>
          <v-card class="my-4 pa-8" outlined>
          <v-row justify="center"><div class="text-h6 font-weight-bold">Naam: </div><div class="text-h6"> {{player.name}}</div></v-row>
        <v-row justify="center"><div class="text-h6 font-weight-bold">Aantal gewonnen: </div><div class="text-h6"> {{player.wins}}</div></v-row>
          <v-row justify="center"><div class="text-h6 font-weight-bold">Aantal verloren: </div><div class="text-h6"> {{player.losses}}</div></v-row>

        <v-row justify="center">
          <div class="text-h6 font-weight-bold" v-if="player.isblocked">Geblokkeerd</div>
        <div class="text-h6 font-weight-bold" v-else>Niet Geblokkeerd</div>
        </v-row>
            <v-row justify="center">
          <v-btn v-if="!player.isblocked">Blokkeren</v-btn>
          <v-btn v-else>Deblokkeren</v-btn>
            </v-row>
          </v-card>
        </v-form>
      </v-row>
    </v-col>
</v-container>
</template>

<script>
import axios from "axios";
import players from "./Players.vue";

export default {
  name: "Admin",
  computed: {
    players() {
      return players
    }
  },
  data(){
    return{
      player:{}
    }
  },
  methods:{
    GetPlayer(){
      axios.get("http://localhost:5180/api/Admin/GetPlayer?playerId="+ this.$route.params.id)
          .then(response =>
          {
            this.player = response.data.data;
            console.log(response)
          });
    }
  },
  created() {
    this.GetPlayer();

    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
  }
}
</script>
