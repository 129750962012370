<template>
<v-container>
  <v-row justify="center">
    <v-col cols="6">
      <form>
      <div class="text-h4 font-weight-bold">Naam</div>
      <v-text-field v-model="userDTO.name"></v-text-field>

      <div class="text-h4 font-weight-bold">Email</div>
      <v-text-field v-model="userDTO.email"></v-text-field>

      <div class="text-h4 font-weight-bold">Wachtwoord</div>
      <v-text-field v-model="userDTO.password" type="password"></v-text-field>
        <v-btn @click="Register">Registreer</v-btn>
      </form>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: "Statistics",
  data() {
    return {
      userDTO: {
        name: "",
        email: "",
        password: ""
      }
    }
  },
  methods: {
    Register(){
      axios.post("http://localhost:5180/api/Account/register", this.userDTO).then(response =>
      {

        console.log(response);
        if (response.data.data.status === 1){
          alert("Kon geen account aanmaken");
        }

        if (response.data.data.status === 0)
        {
          alert("Account aangemaakt");
          this.$router.push("/login")
        }
      })
    }
  },

  created() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
  }
}
</script>
