<template>
<v-container>
  <v-form @submit.prevent>
    <div class="body-2 font-weight-bold">Beschrijving van de game</div>
    <v-text-field v-model="form.description"></v-text-field>
  <v-btn class="my-4" @click="CreateNewGame">Maak nieuwe game</v-btn>
  </v-form>
    <v-row justify="center">
    <v-col cols="6">
      <div class="text-h4 font-weight-bold">Jouw Games</div>
      <v-list>
        <v-list-item v-for="game in this.games" :key="game.id">
        <v-list-item-content>
          <v-list-item-title>{{game.player1.name}}</v-list-item-title>
          <v-list-item-subtitle>{{game.description}}</v-list-item-subtitle>
          <v-btn :to="'/battleship/' + game.id">Ga naar game</v-btn>
        </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    </v-row>
    <v-row justify="center">
    <v-col cols="6">
      <div class="text-h4 font-weight-bold">Openstaande games</div>
      <v-list>
        <v-list-item v-for="otherGame in this.otherPlayerGames" :key="otherGame.id">
          <v-list-item-content>
            <v-list-item-title>{{otherGame.player1.name}}</v-list-item-title>
            <v-list-item-subtitle>{{otherPlayerGames.description}}</v-list-item-subtitle>
            <v-btn @click="JoinGame(otherGame.id)">Ga naar game</v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    </v-row>
</v-container>
</template>
<script>
import axios from "axios";

export default {
  name: "Games",
  data(){
    return {
      games: {},
      otherPlayerGames:{},
      form: {
        description: "",
      }
    }
  },
  methods: {
    async GetGames() {
      await axios.get("http://localhost:5180/api/Game/GetGames?playerId=" + this.$store.state.playerId,
          {
            headers: {
            Authorization: `Bearer ${this.$store.state.jwtToken}`,
              'Content-Type': 'application/json'
          }}
      )
          .then(response => {
            console.log(response)
            this.games = response.data.data;
          });
    },
    GetOtherPlayerGames()
    {

      axios.get("http://localhost:5180/api/Game/GetGamesSearchingPlayers?playerId=" + this.$store.state.playerId)
          .then(response => {
            console.log(response);
            this.otherPlayerGames = response.data.data;
          })
    },
    CreateNewGame()
    {
      if (this.form.description !== "")
      {
        console.log(this.$store.state.playerId)
        axios.post("http://localhost:5180/api/Game/Start?description=" + this.form.description + "&playerId=" + this.$store.state.playerId)
            .then(response => {
              console.log(response)
              this.$router.push("/battleship/" + response.data.data.id);
            });
        console.log("test")
      }
    },

    async JoinGame(gameId){
      axios.post("http://localhost:5180/api/Game/Join?gameId=" + gameId + "&playerId=" + this.$store.state.playerId)
          .then(response => {
            console.log(response)
            this.$router.push("/battleship/" + gameId);
          });
    }
  },
 async created() {
   for (var i = 1; i < 99999; i++) {
     window.clearInterval(i);
   }
     await this.GetGames();
     this.GetOtherPlayerGames()
  }
}
</script>
