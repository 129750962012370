import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    email: "",
    jwtToken: "",
    username: "",
    playerId: "",
  },
  mutations: {
    login(state, payload) {
      state.email = payload.email
      state.jwtToken = payload.jwtToken
      state.username = payload.username
      state.playerId = payload.playerId

      localStorage.setItem("email", payload.email);
      localStorage.setItem("jwtToken", payload.jwtToken);
      localStorage.setItem("username", payload.username);
      localStorage.setItem("playerId", payload.playerId);
      alert("Ingelogd");
    },
    logout(state) {
      state.jwtToken = ''
      state.username = ''
      state.email = ''
      state.email = ''

      localStorage.removeItem("email");
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("username");
      localStorage.removeItem("playerId");

      alert("Uitgelogd");
    },
    initializeStore(state){
      if (localStorage.getItem("email")){
        state.email = localStorage.getItem("email");
        state.jwtToken = localStorage.getItem("jwtToken");
        state.username = localStorage.getItem("username");
        state.playerId = localStorage.getItem("playerId");
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
