<template>
  <v-container>
    <v-row justify="center">
    <v-col cols="6">
    <v-form @submit.prevent>
      <div>Email</div>
      <v-text-field v-model="loginDTO.email"></v-text-field>
      <br><br>
      <div>Wachtwoord</div>
      <v-text-field v-model="loginDTO.password" type="password"></v-text-field>
      <v-btn @click="Login">Login</v-btn>
    </v-form>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoginPage',
  data(){
    return {
      loginDTO:{
        email: '',
        password: '',
      }
    }
  },
  methods: {
    Login(){
      axios.post("http://localhost:5180/api/Account/login", this.loginDTO).then(response =>
      {
        if (response.data.status === 1){
          alert("Kon niet inloggen");
        }

        if (response.data.status === 0)
        {
          alert("Je moet nog 2fa doen die is gestuurd naar je mail");
          this.$router.push({path: '/2fa'});
        }
      })
    }
  },
  created() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
  }
}
</script>
